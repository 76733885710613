import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Page = () => (
  <Layout>
    <SEO title="Become a Humainly Worker | Ghana"/>
    <section id="about">
      <div className="container">
        <div className="t2-padding">
          <h1>Get paid to label images from your home</h1>
          <h4>Become a Humainly Worker</h4>
        </div>
      </div>
    </section>

    <main className="container recruit mt-4">
      <section className="row">
        <div className="col-md-7 ">
          <div className="box-item">
            <div>
              <h3>Description</h3>
              <p>Humainly helps clients build training data for Visual Artificial Intelligence that automatically
                recognizes images. That means we receive a lot of images from them, and then we have to manually add
                labels to those images. That means we create a lot of jobs for people who want to help us do that manual
                labelling work. This job allows you to <b>earn an honest wage, wherever and whenever you want to</b>.
              </p>
            </div>

            <div className="up">
              <h3>Task Examples</h3>
              <ul>
                <li>Adding a simple label to an image e.g. is this image a cat or a dog?</li>
                <li>Drawing tight bounding boxes around objects e.g. mark all vehicles seen by a dashboard camera</li>
                <li>Neatly delineating object contours e.g. drawing lines precisely over the edges of furniture</li>
              </ul>
            </div>

            <div className="up">
              <h3>Requirements</h3>
              <ul>
                <li>You need access to a desktop / laptop with an internet connection</li>
                <li>You need to be older than 18 years.</li>
                <li>You need to have attention for detail (accuracy of the labels is far more important than speed!)
                </li>
                <li>You need to enjoy this type of work :)</li>
              </ul>
            </div>

            <div className="up">
              <h3>Pay</h3>
              <p>You can easily earn <b>5.5 GHS per hour</b> (more if you work faster). We compute how many images
                you should be able to process per hour and convert that to a reward per image. Many people are able to
                do it faster than that, so they can in fact earn a lot more than 5.5 GHS per hour. You <b>get paid
                  every 2 weeks</b> via transfer for the work you've done. </p>
            </div>

            <div className="up text-center">
              <a href="https://app.humainly.com/worker/register?country=GH"
                 className="button button--red button--rounded" target="_blank" rel="noopener noreferrer">GET STARTED NOW</a>
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <div className="box-item">
            <h3>Application Process</h3>
            <ol>
              <li className="up-10"><b>Follow the ‘Apply Now’ button</b> to go to an application form and fill in all
                the details
              </li>
              <li className="up-10">After filling in your basic info, you'll be asked to perform a small image labelling
                challenge.
              </li>
              <li className="up-10">People who perform well on the challenge get invited to start training for a real
                project
              </li>
              <li className="up-10">For each project, there is a training phase for you to understand the task. Don’t
                worry, we’ll do our best to help you!
              </li>
              <li className="up-10">Once the project starts, you get paid every 2 weeks for the work you perform!</li>
            </ol>
            <div className="up text-center">
              <a href="https://app.humainly.com/worker/register?country=GH"
                 className="button button--red button--rounded" target="_blank" rel="noopener noreferrer">APPLY NOW</a>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default Page
